<template>
    <div>
     <el-dialog
      title="查看详情"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      
      <el-form ref="addform" :model="form" label-width="120px" size="mini">
        <div>
         <div><span class="icon_line"></span><span class="title">工程信息</span></div>
         <el-row>
             <el-col :span="12">
          <el-form-item label="工程编号:" prop="positionName">
           {{form.projectNumber}}
          </el-form-item>
             </el-col>
             <el-col :span="12">
          <el-form-item label="工程类型:" prop="positionName">
           {{form.projectType}}
          </el-form-item>
          </el-col>
          <el-form-item label="工程名称:" prop="positionName">
           {{form.projectName}}
          </el-form-item>
          <el-form-item label="工程地点:" prop="positionName">
           {{form.projectSite}}
          </el-form-item>
          <el-col :span="12">
          <el-form-item label="现场管理员:" prop="positionName">
           {{form.siteManager}}
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="第三方施工队:" prop="positionName">
           {{form.constructionTeam}}
          </el-form-item>
          </el-col>
         </el-row>
        </div>
        <div>
           <div><span class="icon_line"></span><span class="title">带气方案信息</span></div>
           <el-row>
               <el-col :span="24">
          <el-form-item label="带气等级:" prop="positionCode">
            {{form.jobLevel==1?'一级':form.jobLevel==2?'二级':'三级'}}
          </el-form-item>
          </el-col>
          <el-col :span="24">
          <el-form-item label="竣工验收签证单:" prop="positionCode">
             <!-- <div>
                 <el-image 
                style="width: 100px; height: 100px"
                :src="form.imageUrlList[0]" 
                :preview-src-list="form.imageUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.imageUrlList.length}}张</span>
            </div> -->
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="上传人:" prop="positionCode">
            {{form.createBy}}
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="上传时间:" prop="positionCode">
            {{form.createTime}}
          </el-form-item>
         </el-col>
         </el-row>
        </div>
        <div>
          <div><span class="icon_line"></span><span class="title">审批信息</span></div>
           <div class="info-content">
          <el-row class="info-item">
            <el-col :span="24">
              <img :src="lhimg" v-if="form.projectBy" class="configImg" alt="" />
              <img :src="garyimg" v-else class="configImg" alt="" />
              部长审批：
            </el-col>
          </el-row>
          <el-row class="info-item" style="margin-left:30px" v-if="form.projectBy">
            <!-- 站位 -->
            <el-col :span="1"></el-col>
            <el-col :span="7">
              <span class="label">工程部：</span>
              <span class="content-item">{{form.projectTypeCheck==1?'通过':'驳回'}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批人：</span>
              <span class="content-item">{{form.projectBy}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批时间:</span>
              <span class="content-item">{{form.projectTime}}</span>
            </el-col>
            <el-col :span="24" v-if="form.projectProposal">
              <span class="label">审批建议:</span>
              <span class="content-item">{{form.projectProposal}}</span>
            </el-col>
            <el-col :span="24" v-if="form.projectReject">
              <span class="label">驳回原因:</span>
              <span class="content-item">{{form.projectReject}}</span>
            </el-col>
          </el-row>
          <el-row class="info-item" style="margin-left:30px" v-if="form.technologyBy">
            <!-- 站位 -->
            <el-col :span="1"></el-col>
            <el-col :span="7">
              <span class="label">技术部：</span>
              <span class="content-item">{{form.technologyType==1?'通过':'驳回'}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批人：</span>
              <span class="content-item">{{form.technologyBy}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批时间:</span>
              <span class="content-item">{{form.technologyTime}}</span>
            </el-col>
            <el-col :span="24" v-if="form.technologyProposal">
              <span class="label">审批建议:</span>
              <span class="content-item">{{form.technologyProposal}}</span>
            </el-col>
            <el-col :span="24" v-if="form.technologyReject">
              <span class="label">驳回原因:</span>
              <span class="content-item">{{form.technologyReject}}</span>
            </el-col>
          </el-row>
          <el-row class="info-item" style="margin-left:30px" v-if="form.operationBy">
            <!-- 站位 -->
            <el-col :span="1"></el-col>
            <el-col :span="7">
              <span class="label">运营部：</span>
              <span class="content-item" v-if="form.operationType">{{form.operationType==1?'通过':'驳回'}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批人：</span>
              <span class="content-item">{{form.operationBy}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批时间:</span>
              <span class="content-item">{{form.operationTime}}</span>
            </el-col>
            <el-col :span="24" v-if="form.operationProposal">
              <span class="label">审批建议:</span>
              <span class="content-item">{{form.operationProposal}}</span>
            </el-col>
            <el-col :span="24" v-if="form.operationReject">
              <span class="label">驳回原因:</span>
              <span class="content-item">{{form.operationReject}}</span>
            </el-col>
          </el-row>
         <el-row class="info-item" v-if="form.jobLevel==2||form.jobLevel==1">
            <el-col :span="8">
              <img :src="lhimg" v-if="form.superiorBy" class="configImg" alt="" />
              <img :src="garyimg" v-else class="configImg" alt="" />
              上级领导审批：
              <span class="content-item" v-if="form.superiorType">{{form.superiorType==1?'通过':'驳回'}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批人：</span>
              <span class="content-item">{{form.superiorBy}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批时间:</span>
              <span class="content-item">{{form.superiorTime}}</span>
            </el-col>
            <el-col :span="24" v-if="form.superiorProposal">
              <span class="label">审批建议:</span>
              <span class="content-item">{{form.superiorProposal}}</span>
            </el-col>
            <el-col :span="24" v-if="form.superiorReject">
              <span class="label">驳回原因:</span>
              <span class="content-item">{{form.superiorReject}}</span>
            </el-col>
          </el-row>
           <el-row class="info-item" v-if="form.jobLevel==1">
            <el-col :span="8">
              <img :src="lhimg" v-if="form.upSuperiorBy" class="configImg" alt="" />
              <img :src="garyimg" v-else class="configImg" alt="" />
              上上级领导审批：
              <span class="content-item"  v-if="form.superiorType">{{form.upSuperiorType==1?'通过':'驳回'}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批人：</span>
              <span class="content-item">{{form.upSuperiorBy}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">审批时间:</span>
              <span class="content-item">{{form.upSuperiorTime}}</span>
            </el-col>
            <el-col :span="24">
              <span class="label" v-if="form.upSuperiorProposal">审批建议:</span>
              <span class="content-item">{{form.upSuperiorProposal}}</span>
            </el-col>
            <el-col :span="24" v-if="form.upSuperiorReject">
              <span class="label">驳回原因:</span>
              <span class="content-item">{{form.upSuperiorReject}}</span>
            </el-col>
          </el-row>
        </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {bzApply_getOperationDetail,bzApply_getApplyInfo} from '../../RequestPort/report/reportApply'
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      form:{},
      lhimg: require("@/assets/lhcomfirm.png"),
      garyimg: require("@/assets/comfirm.png"),
    }
  },
  props: {
    objData: {
      type: Object,
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        this.form = this.objData;
        if(this.form.acceptanceCheckWorkUrl){
            this.form.imageUrlList = this.form.acceptanceCheckWorkUrl.split(',')
        }else{
            this.form.imageUrlList = []
        }
        
        
        
      },
      clearnF(){
        this.objData={}
    },
      
      setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>
<style lang="scss" scoped>
.icon_line{
  width: 3px;
height: 16px;
background: #1082FF;
display: inline-block;
}
.title{
  font-weight: 400;font-size: 16px; margin-left: 5px;height: 16px; line-height: 16px;
}
.info-content {
  padding-left: 30px;
  .info-item {
    margin-bottom: 20px;
  }
  .label {
    color: #909399;
  }
  .content-item {
    color: #444444;
  }
  .configImg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}
</style>
